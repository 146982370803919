import 'babel-polyfill';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';

import ValentineContainer from './containers/ValentineContainer';
import configureStore from './store/configureStore';
import * as serviceWorker from './serviceWorker';
import './index.css';

const store = configureStore();

// store.subscribe(() =>
//   console.log(store.getState())
// )

ReactGA.initialize('UA-115004809-1', {
  debug: false,
  titleCase: false,
  gaOptions: {
  }
});
ReactGA.event({
  category: 'show top',
  action: 'show top page',
});
ReactGA.plugin.require('GTM-TGJCDKR', { path: './', debug: true });

render(
  <Provider store={store}>
    <ValentineContainer />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
