import React from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

import './Button.css';

const OnChangeBtn = ({name, type, title, onChange}) => {
  ReactGA.event({
    category: 'click',
    action: name,
  });

  return (
    <label className="btn btn_judge" htmlFor={name}>{title}
      <input
        id={name}
        type={type}
        name={name}
        onChange={onChange}
      />
    </label>
  )
}

OnChangeBtn.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default OnChangeBtn;
