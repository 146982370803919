import { createStore, applyMiddleware } from 'redux';
import reducer from '../reducers';
import thunk from 'redux-thunk'

export default function configureStore(preloadedState) {
  const store = createStore(
    reducer,
    preloadedState,
    applyMiddleware(
      thunk, // lets us dispatch() functions
    )        
  );
  return store;
}