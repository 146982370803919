import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './ValentineContainer.css';

import * as actions from '../actions';
import Header from '../components/Header/Header';
import OnChangeBtn from '../components/Button/OnChangeBtn';
import OnClickBtn from '../components/Button/OnClickBtn';
import ShowResult from '../components/ShowResult/ShowResult';
import Image from '../components/Image/Image';
import SNS from '../components/SNS/SNS';
// import GoogleAds from '../components/GoogleAds/GoogleAds';

class ValentineContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      url:"https://valentine.boomin.yokohama/",
      hashtag:"AIで本命チョコ判定",
      shareStr:"AIが、あなたのチョコが本命かどうかを判定します",
    }
  }

  /* This life cycle hook gets executed when the component mounts */
  render() {
    // イベントハンドラを受け取っておく
    const { mrstore, actions } = this.props;
    return (
      <div className="App">

        <div className="AppBody">

          <Header />

          {/* <GoogleAds client={"ca-pub-4600286399388912"} slot={"4183158687"} format={"auto"} /> */}

          <Image src={mrstore.base64URL} isLoading={mrstore.isLoading} />

          <div className="btndiv">

            { !mrstore.isSelected ? 
              <OnChangeBtn 
                type={'file'}
                name= {"selectImg"}
                title= {'Select Image File'}
                onChange = {(e) => actions.onGetBase64(e.target)}
              /> : 
              null
            }

            { mrstore.isSelected ? 
              <OnClickBtn
                type={'button'}
                name= {"judgeImg"}
                title= {'本命か義理か判定する'}
                class={ mrstore.isLoading||mrstore.result||mrstore.hasError ? "btn btn_done btn_margin" : "btn btn_calc btn_margin" }
                onClick = {mrstore.isLoading||mrstore.result||mrstore.hasError ? (e) => e.preventDefault() : () => actions.onFetchAPIData(mrstore) }
                disabled={mrstore.isSelected}
              /> : 
              null
            }

            { mrstore.isSelected ? 
              <OnClickBtn 
                type={'reset'}
                name= {"deleteImg"}
                title= {'Delete Image File'}
                class={"btn btn_cansel btn_margin"}
                onClick = {() => actions.onDeleteImage()}
              /> : 
              null
            }

          </div>

          { mrstore.result&&mrstore.isSelected ? 
            <ShowResult 
              status={mrstore.result.resStatus}
              msgs={mrstore.result.message}
              cost={mrstore.result.cost}
              honmei={mrstore.result.honmei}
            /> : 
            null
          }

          <amp-auto-ads type="adsense" data-ad-client="ca-pub-4600286399388912"></amp-auto-ads>        

          <div className="footer">
            <SNS url={this.state.url} hashtag={this.state.hashtag} shareStr={this.state.shareStr} />
            お約束ですが、開発者は判定結果に何の責任も負えません。 <br />
            (C) boomin.yokohama, All right reserved.
          </div>

        </div>
      </div>
    );
  }
}

const mapStateToProps  = (state, ownProps) => ({
  mrstore : state.buttonActionsReducer,
  // apiret  : state.apiFetchActions,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    // fetchData: (url, options) => dispatch(actions.fetchAPIData(url,options)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ValentineContainer);
