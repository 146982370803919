export const INPUT_NUMBER = 'INPUT_NUMBER';
export const PLUS = 'PLUS';
export const SELECT_IMAGE = 'SELECT_IMAGE';
export const DELETE_IMAGE = 'DELETE_IMAGE';
export const JUDGE_IMAGE  = 'JUDGE_IMAGE';


export const FETCH_ERROR = 'FETCH_ERROR'
export const FETCH_SUCCESS = 'FETCH_SUCCESS'
export const FETCH_LOADING = 'FETCH_LOADING'
export const RESIZE_IMAGE = 'RESIZE_IMAGE'


