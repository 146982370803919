import * as actionTypes from '../utils/actionTypes';
require('es6-promise').polyfill();
require('isomorphic-fetch');

// ###############################################

export const onSelectImage = (file, base64) => ({
  type: actionTypes.SELECT_IMAGE,
  file   : file,
  base64 : base64
});

// 非同期でfile readerをコールするアクション
export const onGetBase64 = (etarget) => {
  return (dispatch) => {
    // console.log("etarget.files", etarget.files);
    let file = etarget.files[0]
    // console.log("file", file);
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      // console.log("e.target.result", e.target.result);
      // console.log("e.target.result", URL.createObjectURL(e.target.result));
      dispatch(onSelectImage(file, e.target.result));

      const TO_SIZE = 48;
      // Source Image
      var img = new Image();
      img.onload = function() {
          // New Canvas
          var canvas = document.createElement('canvas');
          canvas.width = TO_SIZE;
          canvas.height = TO_SIZE;
          // Draw (Resize)
          var ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, TO_SIZE, TO_SIZE);
          // Destination Image
          var sendimage = canvas.toDataURL(file.type);
          
          dispatch(onSetResizeB64(sendimage));
        };
      img.src = e.target.result;

    };
  }
}

// ###############################################

export const onSetResizeB64 = (sendimage) => ({
  type: actionTypes.RESIZE_IMAGE,
  resizeImg : sendimage,
});

// 非同期でfile readerをコールするアクション
export const ImgB64Resize = (imgB64_src, mtype) => {
  console.log("ImgB64Resize");

  return (dispatch) => {
    const TO_SIZE = 48;
    // Source Image
    var img = new Image();
    img.onload = function() {
        // New Canvas
        var canvas = document.createElement('canvas');
        canvas.width = TO_SIZE;
        canvas.height = TO_SIZE;
        // Draw (Resize)
        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, TO_SIZE, TO_SIZE);
        // Destination Image
        var sendimage = canvas.toDataURL(mtype);
        dispatch(onSetResizeB64(sendimage));
      };
    img.src = imgB64_src;

  }
}

// ###############################################

export const onDeleteImage = () => ({
  type: actionTypes.DELETE_IMAGE,
});


//////////////////////////////////////// 以下、非同期通信するためのActions

// ユーザ一覧に取得成功し、レスポンスデータをstateに反映するためのアクション
export const fetchError = status => ({
  type: actionTypes.FETCH_ERROR,
  hasError: status
})
export const loadComments = status => ({
  type: actionTypes.FETCH_LOADING,
  isLoading: status
})
export const fetchSuccess = json => ({
  type: actionTypes.FETCH_SUCCESS,
  result: json
})


// 非同期でAPIをコールするアクション
export const onFetchAPIData = (state) => {
  return (dispatch) => {
    dispatch(loadComments(true));
    var formData = new FormData();
    formData.append('param', 0);
    formData.append('image', state.resizeImg.split(";")[1]);
    formData.append('name', state.image.name);
    // console.log("sending image data: ",state.resizeImg.split(";")[1])

    let cryptedKey = "YTQ5ZGIzNzAtMzJjNy0xMWU5LWFlNDMtNTU0NWQ2MmM1MGNibG1rLWxrZGFTQURrcGZhdy00NWFhc0FTRGFtay1sYXM="

    let options = {
      method: 'post',
      crossDomain:true,
      headers: {
        'X-RapidAPI-Proxy-Secret': cryptedKey
      },
      body: formData
    }
    fetch(state.apihost, options)
      .then((response) => {
        if (!response.ok) {
          return Promise.reject('some reason');
          // throw Error(response.statusText);
        } else {
          dispatch(loadComments(false));
          return response;
        }
      })
      .then((response) => response.json())
      .then((result) => dispatch(fetchSuccess(result)))
      .catch(() => dispatch(fetchError(true)));

  }
}
