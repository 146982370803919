import React from 'react';

import '../Button/Button.css';
import './SNS.css';

import {
  TwitterShareButton,
  LineShareButton,
  EmailShareButton,
} from 'react-share';
// import {
//   FacebookShareCount,
// } from 'react-share';
import {
  FacebookIcon,
  TwitterIcon,
  LineIcon,
  EmailIcon,
} from 'react-share';

const SNS = (prop) => {
  let boststr = "\n" + prop.shareStr + "\n" + prop.url;
  const facebookShareURL = "https://www.facebook.com/sharer/sharer.php?u="+prop.url+"&t="+prop.shareStr

  return (
    <div className="SNSArea">

      <a href={facebookShareURL} className="space">
        <FacebookIcon size={32} round={true} />
      </a>

      <TwitterShareButton url={prop.url} title={prop.shareStr} hashtags={[prop.hashtag]} className="space" >
        <TwitterIcon size={32} round />
      </TwitterShareButton>

      <LineShareButton url={prop.url} title={boststr} className="space" >
        <LineIcon size={32} round />
      </LineShareButton>

      <EmailShareButton url={prop.url} subject={prop.hashtag} body={boststr} className="space" >
        <EmailIcon size={32} round />
      </EmailShareButton>

      <a 
        className="btn btn_disc thisbtn"
        href="https://boomin.yokohama/archives/1357"
        title="チョコ画像から義理か本命かを判別する詳しい仕組み"
      >詳しい仕組み</a>


    </div>
  )
}

export default SNS;
