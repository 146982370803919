import React, { Component } from 'react';
import './GoogleAds.css';

class GoogleAds extends Component {

  // マウントが行われた直後に、判定結果をもとにしたメッセージを作ってStateに保持させる
  componentDidMount() {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

  render() {
    // イベントハンドラを受け取っておく
    const { client, slot, format } = this.props;
    return (
      <div className="GoogleAdSense">
        <ins className='adsbygoogle'
          style={{ display: 'block' }}
          data-ad-client={client}
          data-ad-slot={slot}
          data-ad-format={format}
          data-full-width-responsive="true"
        />
      </div>
    );
  }
}

export default GoogleAds;
