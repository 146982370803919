import React from 'react';
import './Image.css';
import loadingImg from './loading-spinning-bubbles.svg';
import ClassNames from 'classnames';

const Image = ({src, isLoading}) => {
  const classImg = ClassNames({
    "showimg": true,
    "opacity": isLoading
  });
  // const testClass = ClassNames({
  //   "showimg": true,
  //   "opacity": true
  // });

  return(
    <div className="imagediv">
      {/* <img alt={testImage} src={testImage} className={classImg} /> */}
      <img alt={src} src={src} className={classImg} />
      {/* <img src={loadingImg} alt="loading" className="imgOverlay" /> */}
      { isLoading ? <img src={loadingImg} alt="loading" className="imgOverlay" /> : null }
    </div>
)};

export default Image;
