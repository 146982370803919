import * as actionTypes from '../utils/actionTypes';

const initialState = {
  apihost:"https://valentine.boomin.yokohama/img",
  // apihost:"http://localhost:8808/img",
  
  isSelected: false,
  image: "",
  base64URL : "",
  resizeImg: undefined,

  isLoading: false,
  hasError:false,
  result: undefined

};

// これはやる！
// https://qiita.com/y_kawase/items/8f1b5a303400a09c4923

export const buttonActionsReducer = (state=initialState, action) => {

  switch (action.type) {

    case actionTypes.FETCH_LOADING:
      return Object.assign({}, state, {isLoading: action.isLoading})
    case actionTypes.FETCH_SUCCESS:
      return Object.assign({}, state, {result: action.result})

    case actionTypes.FETCH_ERROR:
      alert("判定サーバが停止しています。しばらくお待ちください");
      return Object.assign({}, state, {
        hasError: action.hasError,
        isLoading: action.false,
      })

    // 画像削除ボタンの押下
    case actionTypes.DELETE_IMAGE:
      return {
        ...state,
        isSelected: false,
        isLoading: false,
        image    : null,
        base64URL : "",
        resizeImg : "",
        hasError:false,
        result: undefined,
      }

    // 画像選択時にresizeを非同期で実行させたときのイベント
    case actionTypes.RESIZE_IMAGE:
      return Object.assign({}, state, {
        resizeImg: action.resizeImg,
      })

    // 画像選択ボタンの押下
    case actionTypes.SELECT_IMAGE:
      // console.log("action",action)
      if (action.file !== undefined) {
        // console.log("action.file", action.file);
        // console.log("action.base64", action.base64);

        if (checkExtension(action.file)) {
          return Object.assign({}, state, {
            isSelected: true,
            image    : action.file,
            base64URL: action.base64,
          })
        } else {
          return Object.assign({}, state, {
            isSelected: false,
            isLoading: false,
            image    : null,
            base64URL : "",
            resizeImg : "",
            result: undefined,
          })
        }

      } else {
        return state
      }

    default:
      return state;
  }
}


const checkExtension = (image) => {
  let type = image.type.split('/')[0];
  if (type === "image") {
      return true
  } else {
      return false
  }
}

