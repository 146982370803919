import React from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

import './Button.css';

const OnClickBtn = (prop) => {

  ReactGA.event({
    category: 'click',
    action: prop.name,
  });

  return (

    <label className={prop.class} htmlFor={prop.name}>{prop.title}
      <input
        className="btn btn_judge"
        id={prop.name}
        type={prop.type}
        name={prop.name}
        onClick={prop.onClick}
      />
    </label>
  )
}

OnClickBtn.propTypes = {
  onClick: PropTypes.func.isRequired,
};

// const mapStateToProps = state => state.header;

export default OnClickBtn;