// 外部のライブラリやファイルを参照するインポートの部分
import React, { Component } from 'react';
import './ShowResult.css';

class CalcCost extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      cost : 0,
      costStr:"",
    };
  }

  // マウントが行われた直後に、判定結果をもとにしたメッセージを作ってStateに保持させる
  componentDidMount() {
    this.setState({ cost: this.props.cost });
    if (this.props.cost>1){
      this.setState({ costStr: "お返しは" + this.props.cost  + "円くらいがよさそうです。" });
    } else {
      this.setState({ costStr: "" });
    }
  }

  render() {
    return (
      <span className="costArea">
        {this.state.costStr}
      </span>
    );
  }
}

export default CalcCost;