// 外部のライブラリやファイルを参照するインポートの部分
import React, { Component } from 'react';
import './ShowResult.css';
import CalcCost from "./CalcCost"
import GoogleAds from '../GoogleAds/GoogleAds';

class ShowResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      honmei : "%",
      giri : "%",
      resNum : 100,
      retStr : "本命",
      restitle : "",
      msg1 : "",
      msg2 : "",
      lastStr : ["かもしれません","と思われます","の可能性があります","のような気がします"]
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // カテゴリが変わったらデータをリセット
    const i = Math.floor(Math.random()*(prevState.lastStr.length));

    let msg1 = ""
    let msg2 = ""
    let thishonmei = ""
    let thisgiri = ""
    let retStr = ""
    let resti = ""

    if (nextProps.status==="ok"){
      thishonmei = (nextProps.honmei * 100).toFixed(4) + "%";
      thisgiri = ( (1-nextProps.honmei) * 100).toFixed(4) + "%";
      retStr = nextProps.honmei > 0.5 ? "本命" : "義理";

      if (nextProps.honmei >= 0.95) {
        msg1 ="これまで通りの、誠意を示し続けましょう。"
        msg2 = "";
      } else if (nextProps.honmei >= 0.50 && nextProps.honmei < 0.95) {
        msg1 = "でも、" + thisgiri + "の義理成分が含まれているようです。";
        msg2 = "これまで以上に、誠意を見せ続ける努力が必要です。";
      } else if (nextProps.honmei < 0.50 && nextProps.honmei > 0.02) {
        msg1 = "でも、" + thishonmei + "の本命成分が含まれています。";
        msg2 = "希望をもって、誠意を見せ続ける努力が必要でしょう。";
      } else if (nextProps.honmei <= 0.02) {
        msg1 = "しかし、これが人生のすべてではありません。" ;
        msg2 = "これまで通り、誠意をもって生きていきましょう。";
      }
      resti = nextProps.honmei > 0.5 ? thishonmei+retStr+prevState.lastStr[i] : thisgiri+retStr+prevState.lastStr[i];
    } else {
      thishonmei = -1;
      msg1 = nextProps.msgs ;
      msg2 = "";
      retStr = "";
      resti = "";
    }
      

    return {
      honmei  : thishonmei,
      giri    : thisgiri,
      cost    : nextProps.cost,
      retStr  : retStr,
      restitle: resti,
      msg1    : msg1,
      msg2    : msg2,
    }

  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        <div className="ResultDiv MsgArea">
          <div className="resTitle">{this.state.restitle}</div>
          <span className="ResultMsg">{this.state.msg1}<br />{this.state.msg2}</span>
          <CalcCost cost={this.state.cost}></CalcCost>
        </div>
        <GoogleAds client={"ca-pub-4600286399388912"} slot={"1712610883"} format={"auto"} />
      </div>
    );
  }
}

export default ShowResult;